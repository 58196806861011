* {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* Navbar.css */
.navbar-custom {
  background-color: #052545 !important; /* edX primary color */
  margin: 0 !important;
  position: sticky;
  top: 0;
  z-index: 1111111;
}

.blue-bc
{
  background-color: #002985;
}

/* Custom CSS to change navbar toggler color */
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.nav-link {
  padding: 16px !important;
}
.navbar-brand {
  padding: 16px 32px !important;
}
.d-inline-block {
  display: inline-block;
}

.navbar-custom .navbar-brand {
  color: #ffffff;
  font-weight: bold;
}

.navbar-custom .navbar-nav .nav-link {
  color: #ffffff;
  margin-right: 1rem;
  font-weight: bold;
}

.navbar-custom .navbar-nav .nav-link:hover {
  color: #ff6200; /* edX accent color */
}

.navbar-custom .navbar-toggler {
  border: none;
}

.navbar-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.carousel-item {
  position: relative;
  width: 100%;
  height: clac(100vh - 56px);
  margin: 0;
  transition: transform 0.7s ease, opacity 0.7s ease;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-item img {
  height: 500px;
}

.full-height {
  height: 100vh;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item-end,
.carousel-item-start {
  transition: none;
}

.carousel-caption h3 {
  padding: 16px !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value to control the darkness */
  z-index: 1; /* Ensure the overlay is above the image but below the caption */
}

.carousel-item img {
  z-index: 0;
}

.caption-text {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 0 !important;
  left: 15% !important;
  margin: auto;
}

.caption-text h2 {
  font-weight: 600;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.carousel-control-next,
.carousel-control-prev {
  z-index: 1111;
}

.visually-hidden {
  display: none;
}

.carousel-indicators > button {
  visibility: hidden;
}
.full-width-img {
  width: 100%;
  height: auto;
}

h3 {
  padding-bottom: 16px !important;
  font-weight: 600;
}
.bordered {
  border: 1px solid grey;
}
.title {
  font-size: 1rem;
  font-weight: 600;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.no-border {
  border: none;
}
/* .partner
{
  width: 150px;
  margin: auto;
} */
@media screen and (min-width: 360px) {
  .partner {
    width: 40px;
    margin: auto !important;
  }
}
@media screen and (min-width: 480px) {
  .partner {
    width: 50px;
    margin: auto !important;
  }
}
@media screen and (min-width: 556px) {
  .partner {
    width: 60px;
    margin: auto !important;
  }
}
@media screen and (min-width: 766px) {
  .partner {
    width: 89px;
    margin: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .partner {
    width: 110px;
    margin: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  .partner {
    width: 140px;
    margin: auto !important;
  }
}
.centered {
  margin: auto !important;
}

footer {
  padding: 1rem !important;
  text-align: center;
  background-color: #052545;
  color: #fff;
  bottom: 0;
  width: 100%;
  z-index: 1212122121;
}

.pro-button {
  padding: 4px !important;
  border: 2px #000 solid;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
}

.pro-button:hover {
  background-color: #000;
  padding: 4px !important;
  color: #fff;
  border: #000;
  border: 2px #000 solid;
}
.m-r-3 {
  margin-right: 16px !important;
}

@media (max-width: 760px) {
  .custom-margin {
    margin-top: 3rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

.w-app {
  width: 150px;
}
.mini-w-app {
  width: 50px;
  transform: translate(5px, 3px);
}
.w-app-background {
  display: block;
  width: 60px;
  height: 60px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 12px;
  position: fixed;
  bottom: 70px;
  left: 30px;
  z-index: 1212121121;
}

@media screen and (min-width: 766px) {
  .w-app-background {
    display: block;
    width: 170px;
    height: 50px;
    background-color: #fff;
    border-radius: 12px;
    position: fixed;
    bottom: 70px;
    left: 30px;
    z-index: 1212121121;
  }
}

.h-100vh {
  height: calc(100vh - 166px);
}
.h-100vhXD {
  min-height: calc(100vh - 166px);
}
.h-100rvh {
  height: calc(100vh - 118px);
}

.bc-grey {
  background-color: #4f4f4e;
  color: #fff;
}


.Toastify__toast--success {
  top: 60px;
  z-index: 133513151351;
}


.logo
{
  width:50px
}


.mw-540
{
  max-width: 540px;
}
.hours
{
  font-size: 16px;
  color: grey;
}
.outline
{
  font-weight: bold;
  font-size: 1rem;
}

@media screen and (min-width: 992px) {
  .modules
  {
    max-height: 90px;
    overflow-y: auto;
    font-size: 14px;
    line-height: 1;
    padding: 4px !important;
  }
  .course
  {
    height: 340px;
    overflow: hidden
  }
}
.image-holder
{
  height: 171px;
  width: 100%;
  overflow: hidden;
}
@media screen and (max-width: 992px)
{
  .image-holder
  {
    height: 200px !important;
  }
}
@media screen and (max-width: 767px)
{
  .image-holder
  {
    height: 330px !important;
    position: relative;
  }
}
@media screen and (max-width: 480px)
{
  .image-holder
  {
    height: auto !important;
    position: relative;
  }
}